import React, { useCallback, useEffect, useMemo } from 'react';
import { GetServerSideProps, GetServerSidePropsContext, Redirect } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { createClient, fetchApiHubServerSide, getProjectInfo, LocaleStorage, ResponseError, setProjectInfo, useAccount, useCurrentShop, useDarkMode } from '@frontastic-engbers/lib';
import { getTrackingConfig } from '@frontastic-engbers/lib/actions/config';
import { FrontasticRenderer } from '@frontastic-engbers/lib/lib/renderer';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { getPageType } from '@frontastic-engbers/helpers/dataLayerHelper/pageTypeHelper';
import { Log } from '@frontastic-engbers/helpers/errorLogger';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getMetaRobotsContent, useCanonical } from '@frontastic-engbers/helpers/seoHelper';
import { createRedirect, getRedirectForUrl, getSSRRedirect, getUrlFromParams, routeChangeRedirect } from '@frontastic-engbers/helpers/utils/redirect';
import { MetaTags } from '@frontastic-engbers/helpers/metaTags';
import { StructuredData, StructuredDataBreadcrumb } from '@frontastic-engbers/helpers/structuredData';
import { tastics } from '@engbers/tastics';
import { RecoilRoot } from 'recoil';
import GTM from 'react-gtm-module';
import styles from './slug.module.css';
import { FullPageLoadingIndicator, ModalWrap, NotificationWrap } from '@engbers/components';
import { Usercentrics } from '@engbers/components/usercentrics';
import { CleverPush } from '@engbers/components/cleverpush';
import { BackToTopBtn } from '@engbers/components/back-to-top-button';
import { Econda } from '@engbers/components/econda';
import { siteUrl } from 'next-sitemap.config';
import { BreadcrumbHelper } from '@frontastic-engbers/helpers/breadcrumbHelper';
import { isDevelopment } from '@frontastic-engbers/helpers/utils/environment';
if (!isDevelopment()) {
  LocaleStorage.isEngbers = true;
}
const NOT_FOUND_PAGE_SLUG = 'notfound';
type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
  accept: string;
};
export default function Slug({
  data,
  locale,
  accept
}: SlugProps) {
  LocaleStorage.locale = locale;
  LocaleStorage.accept = accept;
  setProjectInfo({
    name: 'engbers_EN',
    projectId: 'en',
    siteUrl
  });
  const projectInfo = getProjectInfo();
  const {
    applyTheme
  } = useDarkMode();
  const router = useRouter();
  const {
    loggedIn,
    loaded,
    account
  } = useAccount();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const tagmanager = new TagManager();
  const {
    getCanonical
  } = useCanonical();
  const currentSection = useCurrentShop();
  const metaRobots = useMemo(() => {
    return getMetaRobotsContent(router.query, router.asPath, data?.pageFolder);
  }, [router.asPath]);
  const handleRouteChange = useCallback(url => {
    routeChangeRedirect({
      loaded,
      loggedIn,
      url,
      account,
      router
    });
  }, [loaded, loggedIn]);
  useEffect(() => {
    // init GTM after 3.5 seconds
    document.addEventListener('DOMContentLoaded', () => {
      setTimeout(initGTM, 3500);
    });
    // init GTM if user interacts with the page
    document.addEventListener('scroll', initGTMOnEvent);
    document.addEventListener('mousemove', initGTMOnEvent);
    document.addEventListener('touchstart', initGTMOnEvent);
    function initGTMOnEvent(event) {
      initGTM();
      event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
    }
    const initGTM = async () => {
      // @ts-ignore
      if (window.gtmDidInit) {
        return false;
      }
      // @ts-ignore
      window.gtmDidInit = true;
      const config = await getTrackingConfig();
      GTM.initialize({
        gtmId: config.gtmContainerId,
        auth: config.gtmEnvironmentAuth,
        preview: `env-${config.gtmEnvironmentId}`
      });
    };
    if (data.awinCookie) {
      localStorage.setItem('awinCookie', JSON.stringify(data.awinCookie));
    }
  }, []);
  useEffect(() => {
    if (getPageType(data) === 'other' || getPageType(data) === 'home' || getPageType(data) === 'nosearchresults') {
      tagmanager.customEvent('ecomm', {
        ecomm_pagetype: getPageType(data),
        ecomm_prodid: [],
        ecomm_totalvalue: 0
      }).executePush();
    }
  }, [data]);
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [loaded, loggedIn]);
  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme);
  }, [data?.pageFolder?.configuration]);
  if (!data || typeof data === 'string') {
    return <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>;
  }
  if (!data!.ok && data!.message) {
    return <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>;
  }
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="[[...slug]].tsx">
        <script dangerouslySetInnerHTML={{
        __html: `
              window.dataLayer = window.dataLayer || [];
                function gtag() {
                dataLayer.push(arguments);
              }

                gtag("consent", "default", {
                ad_user_data: "denied",
                ad_personalization: "denied",
                ad_storage: "denied",
                analytics_storage: "denied",
                wait_for_update: 2000 // milliseconds to wait for update
              });

              gtag("set", "ads_data_redaction", true);
            `
      }} />
        <meta name="description" content={formatMessage({
        id: 'meta.desc',
        defaultMessage: 'Find largest shopping collections here!'
      })} data-sentry-element="meta" data-sentry-source-file="[[...slug]].tsx" />
        <meta name="robots" content={metaRobots} data-sentry-element="meta" data-sentry-source-file="[[...slug]].tsx" />
        <meta name="theme-color" content={currentSection === 'engbers-germany' ? '#342e2b' : '#0f202f'} data-sentry-element="meta" data-sentry-source-file="[[...slug]].tsx" />
        <link {...getCanonical()} />
      </Head>
      <MetaTags {...data.pageFolder.configuration} siteUrl={siteUrl} data-sentry-element="MetaTags" data-sentry-source-file="[[...slug]].tsx" />
      <StructuredData projectId={projectInfo?.projectId} structuredData={data.structuredData} siteUrl={siteUrl} data-sentry-element="StructuredData" data-sentry-source-file="[[...slug]].tsx" />
      <StructuredDataBreadcrumb data={data} data-sentry-element="StructuredDataBreadcrumb" data-sentry-source-file="[[...slug]].tsx" />
      <RecoilRoot data-sentry-element="RecoilRoot" data-sentry-source-file="[[...slug]].tsx">
        <FullPageLoadingIndicator data-sentry-element="FullPageLoadingIndicator" data-sentry-source-file="[[...slug]].tsx" />
        <Usercentrics data-sentry-element="Usercentrics" data-sentry-source-file="[[...slug]].tsx" />
        <CleverPush data-sentry-element="CleverPush" data-sentry-source-file="[[...slug]].tsx" />
        <Econda data={data} account={loggedIn ? account : undefined} locale={locale} data-sentry-element="Econda" data-sentry-source-file="[[...slug]].tsx" />
        <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} data-sentry-element="FrontasticRenderer" data-sentry-source-file="[[...slug]].tsx" />
        <ModalWrap data-sentry-element="ModalWrap" data-sentry-source-file="[[...slug]].tsx" />
        <NotificationWrap data-sentry-element="NotificationWrap" data-sentry-source-file="[[...slug]].tsx" />
        <BackToTopBtn data-sentry-element="BackToTopBtn" data-sentry-source-file="[[...slug]].tsx" />
      </RecoilRoot>
    </>;
}
const getNotFoundPage = ({
  frontastic,
  locale,
  query,
  req,
  res
}: GetServerSidePropsContext & {
  frontastic: any;
}) => {
  res.statusCode = 404;
  return frontastic.getRouteData({
    slug: [NOT_FOUND_PAGE_SLUG]
  }, locale, {
    ...query,
    path: `/${NOT_FOUND_PAGE_SLUG}`
  }, req, res);
};
export const getServerSideProps: GetServerSideProps = async context => {
  const {
    params,
    locale,
    query,
    req,
    res
  } = context;
  LocaleStorage.locale = locale;
  const url = getUrlFromParams(params);
  const specialRedirect = await getRedirectForUrl({
    url,
    req,
    res
  });
  if (specialRedirect) {
    return specialRedirect;
  }
  const ssrRedirect = await getSSRRedirect({
    req,
    res,
    params
  });
  if (ssrRedirect) {
    return ssrRedirect;
  }
  if (req.url.includes('.html')) {
    return createRedirect(req.url.replace('.html', ''), true);
  }
  const frontastic = createClient();
  const data: any = await frontastic.getRouteData(params, locale, query, req, res);
  const structuredData: any = await fetchApiHubServerSide('/action/config/getStructuredData', {
    req,
    res
  });
  if (params.slug?.length === 1 && params.slug?.[0] === NOT_FOUND_PAGE_SLUG) {
    // set 404 for the notfound content page as well
    res.statusCode = 404;
  }
  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      const notFoundPage = await getNotFoundPage({
        frontastic,
        ...context
      });
      return {
        props: {
          data: notFoundPage || null,
          locale: locale,
          ...(await serverSideTranslations(locale, ['common', 'cart', 'product', 'checkout', 'account', 'error', 'success', 'wishlist', 'newsletter']))
        }
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode
        } as Redirect
      };
    }
  }
  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);

    // TODO: should be changed to error page with statusCode 500 instead of notfound at some point
    const notFoundPage = await getNotFoundPage({
      frontastic,
      ...context
    });
    return {
      props: {
        data: notFoundPage || null,
        locale: locale,
        ...(await serverSideTranslations(locale, ['common', 'cart', 'product', 'checkout', 'account', 'error', 'success', 'wishlist', 'newsletter']))
      }
    };
  }
  if (typeof data === 'string') {
    return {
      props: {
        data: {
          error: data
        },
        error: data
      }
    };
  }
  if ((data as any)!.message === 'Could not resolve page from path' || (data as any)?.message?.startsWith?.('No active page for node')) {
    const notFoundPage = await getNotFoundPage({
      frontastic,
      ...context
    });
    return {
      props: {
        data: notFoundPage || null,
        locale: locale,
        ...(await serverSideTranslations(locale, ['common', 'cart', 'product', 'checkout', 'account', 'error', 'success', 'wishlist', 'newsletter']))
      }
    };
  }
  data.pageFolder.customBreadcrumb = await new BreadcrumbHelper(process.env.NEXT_PUBLIC_FRONTASTIC_HOST, 'de_DE').getBreadcrumb(data.pageFolder, url);
  data.awinCookie = req.cookies.aw ?? null;
  data.structuredData = structuredData;
  return {
    props: {
      data: data || null,
      locale: locale,
      accept: req.headers.accept,
      ...(await serverSideTranslations(locale, ['common', 'cart', 'product', 'checkout', 'account', 'error', 'success', 'wishlist', 'newsletter']))
    }
  };
};